import React, { useState } from "react";
import { PiChartPieSlice, PiCubeFocus, PiHammer, PiUser } from "react-icons/pi";
import { Outlet } from "react-router-dom";
import SecondNavBar from "../../components/SecondNavBar/SecondNavBar";

export const FreeSecondNavBarContext = React.createContext();

const Commandes = () => {
  const [freeRef, setFreeRef] = useState(null);
  const handleFreeSecondNavBarRef = (freeSecondNavBarRef) => {
    setFreeRef(freeSecondNavBarRef.current);
  };

  const links = [
    {
      to: "tableau_de_bord",
      icon: <PiChartPieSlice size={"1.5em"} />,
      text: "Tableau de bord",
      type: "link",
    },
    {
      to: "/",
      text: "Paramètres",
      type: "dropdown",
      links: [
        {
          to: "operateurs",
          icon: <PiUser size={"1.5em"} />,
          text: "Opérateurs",
        },
        {
          to: "stockages",
          icon: <PiCubeFocus size={"1.5em"} />,
          text: "Stockages",
        },
        {
          to: "postes",
          icon: <PiHammer size={"1.5em"} />,
          text: "Postes",
        },
      ],
    },
  ];

  return (
    <FreeSecondNavBarContext.Provider value={freeRef}>
      <SecondNavBar links={links} setRef={handleFreeSecondNavBarRef} />
      <Outlet />
    </FreeSecondNavBarContext.Provider>
  );
};

export default Commandes;
