import React, { useState } from "react";
import {
  PiChartPieSlice,
  PiCube,
  PiCubeFocus,
  PiFolderNotchOpen,
  PiHammer,
  PiTimer,
  PiUser,
} from "react-icons/pi";
import { Outlet } from "react-router-dom";
import SecondNavBar from "../../components/SecondNavBar/SecondNavBar";

export const FreeSecondNavBarContext = React.createContext();

const Production = () => {
  const [freeRef, setFreeRef] = useState(null);
  const handleFreeSecondNavBarRef = (freeSecondNavBarRef) => {
    setFreeRef(freeSecondNavBarRef.current);
  };

  const links = [
    {
      to: "tableau_de_bord",
      icon: <PiChartPieSlice size={"1.5em"} />,
      text: "Tableau de bord",
      type: "link",
    },
    {
      to: "produits",
      icon: <PiCube size={"1.5em"} />,
      text: "Etat production",
      type: "link",
    },
    {
      to: "http://192.168.1.240:8081/api.3e/api/xlsx-charge?api_key=3|bXH46OtG1lu2xvRQjbhmp6ixxdjVyPYl5u13H0M37456d1c2",
      icon: <PiTimer size={"1.5em"} />,
      text: "Charge",
      type: "link",
    },
    {
      to: "/",
      icon: <PiFolderNotchOpen size={"1.5em"} />,
      text: "Gestion",
      type: "dropdown",
      links: [
        {
          to: "operateurs",
          icon: <PiUser size={"1.5em"} />,
          text: "Opérateurs",
        },
        {
          to: "stockages",
          icon: <PiCubeFocus size={"1.5em"} />,
          text: "Stockages",
        },
        {
          to: "postes",
          icon: <PiHammer size={"1.5em"} />,
          text: "Postes",
        },
      ],
    },
  ];

  return (
    <FreeSecondNavBarContext.Provider value={freeRef}>
      <SecondNavBar links={links} setRef={handleFreeSecondNavBarRef} />
      <Outlet />
    </FreeSecondNavBarContext.Provider>
  );
};

export default Production;
