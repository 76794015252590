import React, { useContext } from "react";
import { Button, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../App";
import AlignBox from "../../AlignBox/AlignBox";
import UserProfermIcon from "../../CustomIcons/UserProfermIcon/UserProfermIcon";
import "./UserProfilDropdown.css";

const UserProfilDropdown = () => {
  const navigate = useNavigate();
  const userData = useContext(UserContext);

  const nom =
    userData.utilisateur &&
    userData.utilisateur.nom + " " + userData.utilisateur.prenom;
  const groupe = userData.utilisateur && userData.utilisateur.groupe.id;

  const logout = async () => {
    localStorage.removeItem("auth_token");
    navigate("/auth");
  };

  function Title() {
    return (
      <AlignBox>
        {nom}{" "}
        <UserProfermIcon
          size={2}
          idGroupe={groupe}
          reverse={true}
          className="ms-1"
          fillOpacity={"fill-100"}
        />
      </AlignBox>
    );
  }

  return (
    <NavDropdown title={<Title />} className="UserDropdown me-1" align={"end"}>
      <NavDropdown.Item>
        <Button
          onClick={() => {
            logout();
          }}
        >
          Déconnexion
        </Button>
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserProfilDropdown;
